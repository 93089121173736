import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  gridClasses,
} from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef, useState } from "react";
import { RxCross1, RxPlus } from "react-icons/rx";
import { DataGrid } from "@mui/x-data-grid";
import { GoTrash } from "react-icons/go";
import "../View/View.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { HttpService } from "../../../service/HttpService";
import { useEffect } from "react";
import { useAuth } from "../../../context/AuthProvider";
import dayjs from "dayjs";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import utc from "dayjs/plugin/utc";
import SelectInput from "../../SelectInput/SelectInput";

dayjs.extend(utc);

function AddEditSchedule({ open, handleClose, schedule_id, type, isAdd, fetchScheduleData, companyId, locationId }) {
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [rows, setRows] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [plants, setPlants] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const dataGridRef = useRef(null);
  const [scheduleType, setScheduleType] = useState("room");
  const [activities, setActivities] = useState([]);
  const [tasks, setTasks] = useState([]);

  const fetchSchedule = (id, start_date, type, milestone_ids = [], activity_ids = [], task_ids = []) => {
    const payload = {
      id: id,
      type: type,
      start_date: dayjs(start_date).format("MM-DD-YYYY"),
      milestone_ids,
      activity_ids,
      task_ids,
    };
    httpService
      .post("schedule/get_schedules", payload)
      .then(async (response) => {
        const updatedMilestones =
          response?.data &&
          response?.data?.milestones?.map((milestone) => ({
            ...milestone,
            id: milestone.milestone_id,
            name: milestone.milestone_name,
          }));
        setMilestones(updatedMilestones);
        const data = await response?.data?.scheduleData?.map((item, index) => {
          item.selected_employees = item?.selected_employees?.map(Number);
          item.id = index;
          return item;
        });
        if (data?.length === 0) {
          formik?.setFieldValue({
            schedules: [
              {
                id: 0,
                milestone_id: "",
                activity_id: "",
                task_id: "",
                employee_id: [],
                start_datetime: dayjs().format("MM-DD-YYYY hh:mm a"),
                end_datetime: dayjs().format("MM-DD-YYYY hh:mm a"),
              },
            ],
            end_date: dayjs().format("YYYY-MM-DD"),
          });
          toast.warning("No Data Available", {
            position: "top-right",
          });
        }
        formik.setFieldValue(
          "schedules",
          data.map((item, index) => {
            const startTime = item.start_time ? item.start_time.substring(0, 23) : null;
            const endTime = item.end_time ? item.end_time.substring(0, 23) : null;

            return {
              id: index,
              milestone_id: item.milestone_id || "",
              activity_id: item.activity_id || "",
              task_id: item.task_id || "",
              employee_id: item.selected_employees || [],
              start_datetime: startTime
                ? dayjs.utc(startTime).format("MM-DD-YYYY hh:mm a")
                : dayjs().format("MM-DD-YYYY hh:mm a"),
              end_datetime: endTime
                ? dayjs.utc(endTime).format("MM-DD-YYYY hh:mm a")
                : dayjs().format("MM-DD-YYYY hh:mm a"),
            };
          })
        );
        // Find the maximum end_time
        const maxEndTime = data?.reduce((max, item) => {
          return item.end_time > max ? item.end_time : max;
        }, data[0]?.end_time);
        // Set the end_date to the maximum end_time
        formik.setFieldValue("end_date", maxEndTime ? maxEndTime : dayjs().format("YYYY-MM-DD"));
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchMilestoneBYRoom = (plant_id, room_id, type) => {
    const id = type === "room" ? room_id : plant_id;
    if (id) {
      const payload = {
        id: id,
        type: type,
      };
      httpService
        .post("schedule/rooms/milestones", payload)
        .then(async (response) => {
          const updatedMilestones =
            response?.data &&
            response?.data.map((milestone) => ({
              ...milestone,
              id: milestone.milestone_id,
              name: milestone.milestone_name,
            }));
          setMilestones(updatedMilestones);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const fetchActivityBYMilestone = (id, payload) => {
    httpService
      .post("schedule/milestones/activities", payload)
      .then(async (response) => {
        const updatedRows = [...rows];
        if (id) {
          updatedRows[id].activities = response?.data;
        }
        setRows(updatedRows);
        setActivities(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchTaskBYActivity = (id, payload) => {
    httpService
      .post("schedule/activities/tasks", payload)
      .then(async (response) => {
        const updatedRows = [...rows];
        if (id) {
          updatedRows[id].tasks = response?.data;
        }
        setRows(updatedRows);
        setTasks(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchEMPbyTask = async (id, payload) => {
    await httpService
      .post("schedule/tasks/employees", payload)
      .then(async (response) => {
        if (response?.data) {
          const updatedRows = [...rows];
          updatedRows[id].employees = response?.data.map((item) => ({
            ...item,
            id: Number(item.id),
          }));
          updatedRows[id].selected_employees = response?.data?.length > 0 ? [parseInt(response?.data[0]?.id)] : [];
          formik.setFieldValue(
            `schedules.${id}.employee_id`,
            response?.data?.length > 0 ? [parseInt(response?.data[0]?.id)] : []
          );
          if (updatedRows[id]?.selected_employees.length >= 0) {
            setRows(updatedRows);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPlants = () => {
    setLoading(true);
    httpService
      .get("plant/get_plant")
      .then((res) => {
        setPlants(res?.data?.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const fetchRooms = () => {
    setLoading(true);
    httpService
      .get("rooms")
      .then((res) => {
        setRooms(res?.data?.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRooms();
    fetchPlants();
    if (schedule_id) {
      fetchScheduleById();
    }
  }, [showLoginPopup, schedule_id]);

  const handleAddRow = () => {
    const newRow = {
      id: rows.length, // Generate unique id for new row
      milestone_id: "",
      activity_id: "",
      task_id: "",
      employees: [],
      selected_employees: [],
      activities: [],
      tasks: [],
      start_datetime: dayjs().format("MM-DD-YYYY hh:mm a"),
      end_datetime: dayjs().format("MM-DD-YYYY hh:mm a"),
    };

    const forFormik = {
      id: rows.length,
      milestone_id: "",
      activity_id: "",
      task_id: "",
      employee_id: [],
      start_datetime: dayjs().format("MM-DD-YYYY hh:mm a"),
      end_datetime: dayjs().format("MM-DD-YYYY hh:mm a"),
    };
    setRows([...rows, newRow]);
    formik.setFieldValue("schedules", [...formik.values.schedules, forFormik]);
    if (dataGridRef.current && dataGridRef.current.gridRef) {
      setTimeout(() => {
        const gridContainer = dataGridRef.current.gridRef.current;
        if (gridContainer) {
          gridContainer.scrollTop = gridContainer.scrollHeight;
        }
      }, 100);
    }
  };

  const handleDeleteRow = (id) => {
    setSelectedRowId(id); // Set selected row id
    setOpenDeleteModal(true); // Open delete modal
  };

  const handleDeleteConfirm = () => {
    const updatedRows = rows.filter((row) => row.id !== selectedRowId);
    const updateSchedules = formik.values.schedules.filter((row) => row.id !== selectedRowId);
    formik.setFieldValue("schedules", updateSchedules);
    setRows(updatedRows);
    setOpenDeleteModal(false); // Close delete modal
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      plant_id: "",
      room_id: "",
      start_date: "",
      end_date: "",
      milestones: [],
      activities: [],
      tasks: [],
      schedules: rows.map(() => ({
        milestone_id: "",
        activity_id: "",
        task_id: "",
        employee_id: [],
        start_time: dayjs().format("MM-DD-YYYY hh:mm a") || "",
        end_time: dayjs().format("MM-DD-YYYY hh:mm a") || "",
        start_datetime: dayjs().format("MM-DD-YYYY hh:mm a") || "",
        end_datetime: dayjs().format("MM-DD-YYYY hh:mm a") || "",
      })),
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      plant_id: Yup.string().required("Please Select Plant"),
      room_id: Yup.string().required("Please Select Room"),
      start_date: Yup.date()
        .required("Please Select Start Date")
        .test("is-start-date-valid", "Start Date should not be greater than End Date", function (value) {
          const { end_date } = this.parent;
          return !end_date || value <= new Date(end_date);
        }),
      end_date: Yup.date().test("is-end-date-valid", "End Date should not be less than Start Date", function (value) {
        const { start_date } = this.parent;
        return !start_date || value >= new Date(start_date);
      }),

      schedules: Yup.array().of(
        Yup.object().shape({
          milestone_id: Yup.string().required("Please Select Milestone"),
          activity_id: Yup.string().required("Please Select Activity"),
          task_id: Yup.string().required("Please Select Task"),
          employee_id: Yup.array().min(1, "Please Select at least one Employee").required("Please Select Employee"),
          start_datetime: Yup.date()
            .required("Please Select Start Date")
            .test(
              "is-start-datetime-valid",
              "Start DateTime should not be greater than End DateTime",
              function (value) {
                const { end_datetime } = this.parent;
                return !end_datetime || value <= new Date(end_datetime);
              }
            ),
          end_datetime: Yup.date()
            .required("Please Select End Date")
            .test("is-end-datetime-valid", "End DateTime should not be less than Start DateTime", function (value) {
              const { start_datetime } = this.parent;
              return !start_datetime || value >= new Date(start_datetime);
            }),
        })
      ),
    }),
    onSubmit: async (values) => {
      const newvalues = { ...values, type: scheduleType };

      if (newvalues.schedules?.length > 0) {
        setLoading(true);
        try {
          if (schedule_id === "") {
            await httpService.post(`${"schedule/add_schedule"}`, newvalues);
            toast.success("Schedule Added Successfully", {
              position: "top-right",
            });
          } else {
            await httpService.put(`schedule/edit_schedule/${schedule_id}`, newvalues);
            toast.success("Schedule Updated Successfully", {
              position: "top-right",
            });
          }
          setLoading(false);
          handleClose();
          fetchScheduleData();
        } catch (error) {
          setLoading(false);
          toast.error(`${error.message}`, {
            position: "top-right",
          });
        }
      } else {
        toast.warning("There is no task to create a schedule", {
          position: "top-right",
        });
      }
    },
  });

  const fetchScheduleById = () => {
    if (schedule_id) {
      httpService
        .get(`schedule/get_schedule/${schedule_id}`)
        .then(async (response) => {
          if (response?.data) {
            const res = response?.data;
            formik.setFieldValue("plant_id", res?.plant_id);
            formik.setFieldValue("room_id", res?.room_id);
            formik.setFieldValue("start_date", dayjs(res?.start_date));
            formik.setFieldValue("end_date", dayjs(res?.end_date));
            formik.setFieldValue("name", res?.name);
            setScheduleType(res?.type);
            setMilestones(res?.milestones);
            if (res?.milestones?.length === 0) {
              fetchMilestoneBYRoom(res?.plant_id, res?.room_id, res?.type);
            }
            if (res?.schedules && res?.schedules?.length > 0) {
              const data = await res?.schedules?.map((item, index) => {
                item.id = index;
                if (item.selected_employees) {
                  if (Array.isArray(item.selected_employees) && item.selected_employees.length > 0) {
                    if (typeof item.selected_employees[0] === "object" && "id" in item.selected_employees[0]) {
                      item.selected_employees = item.selected_employees.map((e) => e.id);
                    }
                  } else {
                    item.selected_employees = item.employees[0]?.id ? [item.employees[0].id] : [];
                  }
                } else {
                  item.selected_employees = item.employees[0]?.id ? [item.employees[0].id] : [];
                }
                item.start_time = item.start_date;
                item.end_time = item.end_date;
                delete item.start_date;
                delete item.end_date;
                return item;
              });
              formik.setFieldValue(
                "schedules",
                res?.schedules.map((item, index) => ({
                  id: index,
                  milestone_id: item?.milestone_id || "",
                  activity_id: item?.activity_id || "",
                  task_id: item?.task_id || "",
                  employee_id: item?.selected_employees || [],
                  start_datetime: item?.start_time,
                  end_datetime: item?.end_time,
                }))
              );
              setRows(data);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const getScheduleData = (plant_id, room_id, start_date) => {
    if ((plant_id || room_id) && start_date) {
      const id = scheduleType === "room" ? room_id : plant_id;
      if (id === "" || id === undefined || id === null) {
        if (room_id === "" || room_id === undefined || room_id === null) {
          return toast.error("Please Select Room Name");
        } else {
          return toast.error("Please Select Plant Name");
        }
      }
      fetchSchedule(
        id,
        start_date,
        scheduleType,
        formik.values.milestones,
        formik.values.activities,
        formik.values.tasks
      );
    }
  };

  const getScheduleFromType = (type) => {
    if ((formik.values.room_id || formik.values.plant_id) && formik.values.start_date) {
      const id = type === "room" ? formik.values.room_id : formik.values.plant_id;
      if (id === "" || id === undefined || id === null) {
        if (formik.values.room_id === "" || formik.values.room_id === undefined || formik.values.room_id === null) {
          return toast.error("Please Select Room Name");
        } else {
          return toast.error("Please Select Plant Name");
        }
      }
      fetchSchedule(
        id,
        formik.values.start_date,
        type,
        formik.values.milestones,
        formik.values.activities,
        formik.values.tasks
      );
    }
  };

  const handleChange = (id, field, value) => {
    const updatedRows = [...rows];
    updatedRows[id][field] = value;
    formik.setFieldValue(
      "schedules",
      updatedRows.map((item, index) => {
        // const startDate = item.start_date
        //   ? item.start_date.substring(0, 23)
        //   : null;
        const startTime = item.start_time ? item.start_time.substring(0, 23) : null;
        // const endDate = item.end_date ? item.end_date.substring(0, 23) : null;
        const endTime = item.end_time ? item.end_time.substring(0, 23) : null;

        return {
          id: index,
          milestone_id: item.milestone_id || "",
          activity_id: item.activity_id || "",
          task_id: item.task_id || "",
          employee_id: item.selected_employees || [],
          start_datetime: startTime
            ? dayjs.utc(startTime).format("MM-DD-YYYY hh:mm a")
            : dayjs().format("MM-DD-YYYY hh:mm a"),
          end_datetime: endTime
            ? dayjs.utc(endTime).format("MM-DD-YYYY hh:mm a")
            : dayjs().format("MM-DD-YYYY hh:mm a"),
        };
      })
    );
    setRows(updatedRows);
  };

  const removeSelectItem = (id, field) => {
    if (field === "milestone_id") {
      handleChange(id, "activity_id", "");
      handleChange(id, "activity_name", "");
      handleChange(id, "activities", "");
      handleChange(id, "task_id", "");
      handleChange(id, "task_name", "");
      handleChange(id, "tasks", "");
      handleChange(id, "employees", []);
      handleChange(id, "selected_employees", []);
    } else if (field === "activity_id") {
      handleChange(id, "task_id", "");
      handleChange(id, "task_name", "");
      handleChange(id, "tasks", "");
      handleChange(id, "employees", []);
      handleChange(id, "selected_employees", []);
    } else if (field === "task_id") {
      handleChange(id, "employees", []);
      handleChange(id, "selected_employees", []);
    }
  };

  const replaceItemById = (arr, updatedItems) => {
    const newArr = [...arr];
    updatedItems.forEach((updatedItem) => {
      const index = newArr.findIndex((item) => item.id === updatedItem.id);
      if (index !== -1) {
        newArr[index] = updatedItem;
      }
    });
    return newArr;
  };

  const updateDates = async (id, newStartDate, newEndDate, currentRecord) => {
    const newArr = [];
    rows.forEach((row) => {
      if (currentRecord) {
        if (row?.id >= id) {
          newArr.push(row);
        }
      } else {
        if (row.id > id) {
          newArr.push(row);
        }
      }
    });

    const payload = {
      start_date: currentRecord ? newStartDate : newEndDate,
      schedule: newArr,
    };

    try {
      const response = await httpService.post("schedule/get_updatedSchedule", payload);
      const newRows = replaceItemById(rows, response?.data?.scheduleData);
      formik.setFieldValue(
        "schedules",
        newRows.map((item, index) => {
          return {
            id: index,
            milestone_id: item.milestone_id || "",
            activity_id: item.activity_id || "",
            task_id: item.task_id || "",
            employee_id: item.selected_employees || [],
            start_datetime: item.start_time ? dayjs.utc(item.start_time).format("MM-DD-YYYY hh:mm a") : "",
            end_datetime: item.end_time ? dayjs.utc(item.end_time).format("MM-DD-YYYY hh:mm a") : "",
          };
        })
      );
      setRows(newRows);
    } catch (error) {
      console.error("Error updating schedule:", error);
    }
  };
  const handleMouseDown = (event) => {
    event.stopPropagation();
  };
  const inputRef = useRef(null);
  const handleFocus = (event) => {
    event.stopPropagation();
  };
  const renderSelect = (params, options) => {
    const uniqueDatas =
      params.row[options.dataKey]?.length &&
      params.row[options.dataKey]?.filter(
        (activity, index, self) => index === self.findIndex((t) => t.id === activity.id)
      );
    const [employeeSearchInput, setEmployeeSearchInput] = useState("");

    const handleEmployeeSearchInputChange = (event) => {
      const inputValue = event.target.value.toLowerCase();
      setEmployeeSearchInput(inputValue);
    };

    const filteredEmployees = params.row.employees.filter((employee) =>
      employee.name.toLowerCase().includes(employeeSearchInput.toLowerCase())
    );
    return (
      <div className="w-100" key={`${options.field}-${params.row.id}`}>
        <FormControl
          fullWidth
          className="text-filed-wrap"
          error={
            formik.touched.schedules?.[params.id]?.[options.field] &&
            Boolean(formik.errors.schedules?.[params.id]?.[options.field])
          }>
          <Select
            id={`demo-simple-select-${options.field}-${params.id}`}
            name={`schedules.${params.id}.${options.field}`}
            value={
              options.field === "employee_id" && params.row.selected_employees
                ? params.row.selected_employees
                : params.row[options.field]
            }
            onChange={(event) => {
              if (options.field === "employee_id") {
                const value = event.target.value;
                if (value[value?.length - 1] === "all") {
                  const selectedIds =
                    params?.row?.selected_employees?.length === params?.row?.employees?.map((item) => item.id)?.length
                      ? []
                      : params.row.employees.map((item) => item.id);
                  const updatedRows = [...rows];
                  updatedRows[params.id]["selected_employees"] = selectedIds;
                  setRows(updatedRows);
                  formik.setFieldValue(`schedules.${params.id}.employee_id`, selectedIds);
                  formik.validateField(`schedules.${params.id}.employee_id`);
                  return;
                } else {
                  handleChange(params.id, "selected_employees", event.target.value);
                  formik.setFieldValue(`schedules.${params.id}.employee_id`, value);
                  formik.validateField(`schedules.${params.id}.employee_id`);
                }
              } else {
                handleChange(params.id, options.field, event.target.value);
                formik.setFieldValue(`schedules.${params.id}.${options.field}`, event.target.value);
                formik.validateField(`schedules.${params.id}.${options.field}`);
                if (options.field === "milestone_id") {
                  removeSelectItem(params.id, "milestone_id");
                  const payload = {
                    id: scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                    type: scheduleType,
                    milestone_id: event.target.value,
                  };
                  fetchActivityBYMilestone(params.id, payload);
                } else if (options.field === "activity_id") {
                  removeSelectItem(params.id, "activity_id");
                  const payload = {
                    activity_id: event.target.value,
                  };
                  fetchTaskBYActivity(params.id, payload);
                } else if (options.field === "task_id") {
                  removeSelectItem(params.id, "task_id");
                  const payload = {
                    task_id: event.target.value,
                  };
                  fetchEMPbyTask(params.id, payload);
                }
              }
            }}
            displayEmpty
            multiple={options.field === "employee_id"}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              keepMounted: true,
              disableAutoFocusItem: true,
              PaperProps: {
                onMouseDown: handleMouseDown,
              },
            }}
            renderValue={
              options.field === "employee_id"
                ? (selected) => {
                    if (selected?.length === 0) {
                      return <span>Select Employee</span>;
                    }
                    const selectedData = selected.map(
                      (id) => params?.row?.employees?.find((item) => parseInt(item.id) === parseInt(id))?.name
                    );
                    return selectedData.join(", ");
                  }
                : undefined
            }
            input={<OutlinedInput />}>
            {options.field === "employee_id" && (
              <div onMouseDown={handleMouseDown}>
                <TextField
                  placeholder="Search employees..."
                  fullWidth
                  onChange={handleEmployeeSearchInputChange}
                  value={employeeSearchInput}
                  style={{ padding: "12px" }}
                  onFocus={handleFocus}
                  inputRef={inputRef}
                  InputProps={{
                    onFocus: (e) => e.stopPropagation(),
                  }}
                />
              </div>
            )}
            {options.field === "employee_id"
              ? [
                  params.row[options.dataKey]?.length > 0 && (
                    <MenuItem key="select-all" value="all">
                      <ListItemIcon>
                        <Checkbox
                          checked={params.row.selected_employees.length === params.row[options.dataKey].length}
                          indeterminate={
                            params.row.selected_employees.length > 0 &&
                            params.row.selected_employees.length < params.row[options.dataKey].length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  ),

                  filteredEmployees?.length > 0 &&
                    filteredEmployees.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        <Checkbox
                          checked={
                            params?.row?.selected_employees?.length > 0 &&
                            params?.row?.selected_employees.some((id) => {
                              if (typeof id === "string") {
                                // Convert string IDs to numbers for comparison
                                return parseInt(id) === parseInt(item.id);
                              } else {
                                // Keep number IDs as they are for comparison
                                return parseInt(id) === parseInt(item.id);
                              }
                            })
                          }
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    )),
                ]
              : [
                  <MenuItem value="" disabled>
                    Select {options.label}
                  </MenuItem>,
                  params?.field === "milestone"
                    ? milestones?.length > 0 &&
                      milestones?.map((data) => (
                        <MenuItem key={`milestone-${data.milestone_id}`} value={data.milestone_id}>
                          {data.milestone_name}
                        </MenuItem>
                      ))
                    : uniqueDatas?.length > 0 &&
                      uniqueDatas.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      )),
                ]}
          </Select>
          {formik.touched.schedules?.[params.id]?.[options.field] &&
            formik.errors.schedules?.[params.id]?.[options.field] && (
              <FormHelperText>{formik.errors.schedules[params.id][options.field]}</FormHelperText>
            )}
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <Modal
        className="milestone-modal schedule_modal"
        open={open}
        // onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          {loading && <FullScreenLoader />}
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <div className="modal_block">
              <div className="modal_header">
                <div className="modal_title_wrapper">
                  <h2 className="modal_title">{schedule_id ? "Edit Schedule" : "Add Schedule"}</h2>
                </div>
                <div className="cancel-btn-wrap">
                  <RxCross1 onClick={() => handleClose()} />
                </div>
              </div>
              <div className="modal_content">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form_field_wrapper">
                      <label>
                        Schedule Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        id="name"
                        name="name"
                        placeholder="Enter Your Schedule Name"
                        onChange={formik.handleChange}
                        variant="outlined"
                        onBlur={formik?.handleBlur}
                        value={formik?.values.name}
                      />
                      {formik?.errors.name && formik?.touched.name && (
                        <span style={{ color: "red" }}>{formik?.errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <label>Schedule Options</label>
                      <div className="fieldwithradio">
                        <FormControl fullWidth>
                          <Select
                            id="demo-simple-select-room"
                            name="room_id"
                            displayEmpty
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              keepMounted: true,
                            }}
                            value={scheduleType}
                            onChange={(e) => {
                              setScheduleType(e.target.value);
                              getScheduleFromType(e.target.value);
                            }}
                            renderValue={(select) => {
                              return select
                                .split(" ")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ");
                            }}>
                            <MenuItem value="" disabled>
                              Schedule Option
                            </MenuItem>
                            <MenuItem value="plant">
                              <ListItemText primary="Plant" />
                            </MenuItem>
                            <MenuItem value="room">
                              <ListItemText primary="Room" />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_field_wrapper">
                      <label>
                        Plant Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="fieldwithradio">
                        {/* <Radio
                        checked={scheduleType === "plant"}
                        onChange={radiohandleChange}
                        value="plant"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      /> */}
                        <FormControl key="plant" fullWidth>
                          <Select
                            id="demo-simple-select-plant"
                            name="plant_id"
                            value={formik.values.plant_id}
                            onChange={(event) => {
                              formik.setFieldValue("plant_id", event.target.value);
                              formik.setFieldValue("tasks", []);
                              formik.setFieldValue("milestones", []);
                              formik.setFieldValue("activities", []);
                              setMilestones([]);
                              setActivities([]);
                              setTasks([]);
                              getScheduleData(event.target.value, formik.values.room_id, formik.values.start_date);
                            }}
                            onBlur={formik.handleBlur}
                            displayEmpty
                            MenuProps={{
                              getcontentanchorel: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              keepMounted: true,
                            }}>
                            <MenuItem value="" selected disabled>
                              Select Plant
                            </MenuItem>
                            {plants?.length > 0 &&
                              plants?.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                  {data.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      {formik.touched.plant_id && formik.errors.plant_id ? (
                        <div style={{ color: "red" }}>{formik.errors.plant_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_field_wrapper">
                      <label>
                        Room Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="fieldwithradio">
                        {/* <Radio
                        checked={scheduleType === "room"}
                        onChange={radiohandleChange}
                        value="room"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "B" }}
                      /> */}

                        <FormControl key={"room"} fullWidth>
                          <Select
                            id="demo-simple-select-room"
                            name="room_id"
                            value={formik.values.room_id}
                            onChange={(event) => {
                              formik.setFieldValue("room_id", event.target.value);
                              formik.setFieldValue("tasks", []);
                              formik.setFieldValue("milestones", []);
                              formik.setFieldValue("activities", []);
                              setMilestones([]);
                              setActivities([]);
                              setTasks([]);
                              getScheduleData(formik.values.plant_id, event.target.value, formik.values.start_date);
                            }}
                            onBlur={formik.handleBlur}
                            displayEmpty
                            MenuProps={{
                              getcontentanchorel: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              keepMounted: true,
                            }}>
                            <MenuItem value="" selected disabled>
                              Select Room
                            </MenuItem>
                            {rooms?.length > 0 &&
                              rooms?.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                  {data.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      {formik.touched.room_id && formik.errors.room_id ? (
                        <div style={{ color: "red" }}>{formik.errors.room_id}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <label>
                        Start Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          key="idst"
                          name="start_date"
                          format="MM-DD-YYYY"
                          value={formik.values.start_date ? dayjs(formik.values.start_date) : null}
                          disablePast
                          onChange={(date) => {
                            const formattedDate = date ? date.format("MM-DD-YYYY") : null;
                            formik.setFieldValue("start_date", formattedDate);
                            getScheduleData(formik.values.plant_id, formik.values.room_id, formattedDate);
                            fetchMilestoneBYRoom(formik.values.plant_id, formik.values.room_id, scheduleType);
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </LocalizationProvider>
                      {formik.touched.start_date && formik.errors.start_date ? (
                        <div style={{ color: "red" }}>{formik.errors.start_date}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form_field_wrapper">
                      <label>End Date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="end_date"
                          key="ided"
                          format="MM-DD-YYYY"
                          value={formik.values.end_date ? dayjs(formik.values.end_date) : null}
                          disablePast
                          onChange={(date) => {
                            const formattedDate = date ? date.format("MM-DD-YYYY") : null;
                            formik.setFieldValue("end_date", formattedDate);
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <SelectInput
                      label="Milestone"
                      name="milestones"
                      value={formik.values.milestones || []}
                      options={milestones}
                      selectAll={true}
                      multiple={true}
                      onChange={(e) => {
                        formik.setFieldValue("tasks", []);
                        formik.setFieldValue("activities", []);
                        setActivities([]);
                        setTasks([]);
                        const value = e.target.value;
                        if (value[value.length - 1] === "all") {
                          const selectedIds =
                            formik.values.milestones?.length === milestones.length
                              ? []
                              : milestones.map((item) => item.id);
                          formik.setFieldValue("milestones", selectedIds);
                          if (selectedIds.length > 0) {
                            const payload = {
                              id: scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                              type: scheduleType,
                              milestone_id: selectedIds,
                            };
                            fetchSchedule(
                              scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                              formik.values.start_date,
                              scheduleType,
                              selectedIds,
                              formik.values.activities,
                              formik.values.tasks
                            );
                            fetchActivityBYMilestone("", payload);
                          }
                          return;
                        }
                        formik.setFieldValue("milestones", value);
                        if (value) {
                          const payload = {
                            id: scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                            type: scheduleType,
                            milestone_id: value,
                          };
                          fetchSchedule(
                            scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                            formik.values.start_date,
                            scheduleType,
                            value,
                            formik.values.activities,
                            formik.values.tasks
                          );
                          fetchActivityBYMilestone("", payload);
                        }
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.milestones && formik.errors.milestones}
                      required={false}
                      displayEmpty
                      disabled={companyId > 0 ? true : false}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectInput
                      label="Activities"
                      name="activities"
                      value={formik.values.activities || []}
                      options={activities}
                      selectAll={true}
                      multiple={true}
                      onChange={(e) => {
                        formik.setFieldValue("tasks", []);
                        setTasks([]);
                        const value = e.target.value;
                        if (value[value.length - 1] === "all") {
                          const selectedIds =
                            formik.values.activities?.length === activities.length
                              ? []
                              : activities.map((item) => item.id);
                          formik.setFieldValue("activities", selectedIds);
                          if (selectedIds?.length > 0) {
                            const payload = {
                              activity_id: selectedIds,
                            };
                            fetchSchedule(
                              scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                              formik.values.start_date,
                              scheduleType,
                              formik.values.milestones,
                              selectedIds,
                              formik.values.tasks
                            );
                            fetchTaskBYActivity("", payload);
                          }
                          return;
                        }
                        formik.setFieldValue("activities", value);
                        if (value) {
                          const payload = {
                            activity_id: value,
                          };
                          fetchSchedule(
                            scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                            formik.values.start_date,
                            scheduleType,
                            formik.values.milestones,
                            value,
                            formik.values.tasks
                          );
                          fetchTaskBYActivity("", payload);
                        }
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.activities && formik.errors.activities}
                      required={false}
                      displayEmpty
                      disabled={companyId > 0 ? true : false}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectInput
                      label="Task"
                      name="tasks"
                      value={formik.values.tasks || []}
                      options={tasks}
                      selectAll={true}
                      multiple={true}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value[value.length - 1] === "all") {
                          const selectedIds =
                            formik.values.tasks?.length === tasks.length ? [] : tasks.map((item) => item.id);
                          fetchSchedule(
                            scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                            formik.values.start_date,
                            scheduleType,
                            formik.values.milestones,
                            formik.values.activities,
                            selectedIds
                          );
                          formik.setFieldValue("tasks", selectedIds);
                          return;
                        }
                        fetchSchedule(
                          scheduleType === "room" ? formik.values.room_id : formik.values.plant_id,
                          formik.values.start_date,
                          scheduleType,
                          formik.values.milestones,
                          formik.values.activities,
                          value
                        );
                        formik.setFieldValue("tasks", value);
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      error={formik.touched.tasks && formik.errors.tasks}
                      required={false}
                      displayEmpty
                      disabled={companyId > 0 ? true : false}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="schedule-addedit-table" style={{ height: 500, width: "100%" }}>
                      <DataGrid
                        ref={dataGridRef}
                        rows={rows}
                        getRowHeight={() => "auto"}
                        getRowClassName={(params) => (params?.row?.conflicted_status ? "highlight" : "")}
                        sx={{
                          [`& .${gridClasses.cell}`]: {
                            py: 0.5,
                          },
                        }}
                        columns={[
                          {
                            field: "milestone",
                            headerName: "Milestone",
                            width: 160,
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) =>
                              renderSelect(params, {
                                field: "milestone_id",
                                label: "Milestone",
                                dataKey: "milestones",
                              }),
                          },
                          {
                            field: "activity",
                            headerName: "Activity",
                            width: 160,
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) =>
                              renderSelect(params, {
                                field: "activity_id",
                                label: "Activity",
                                dataKey: "activities",
                              }),
                          },
                          {
                            field: "task",
                            headerName: "Task",
                            width: 160,
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) =>
                              renderSelect(params, {
                                field: "task_id",
                                label: "Task",
                                dataKey: "tasks",
                              }),
                          },
                          {
                            field: "employee",
                            headerName: "Employee",
                            width: 160,
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) =>
                              renderSelect(params, {
                                field: "employee_id",
                                label: "Employee",
                                dataKey: "employees",
                              }),
                          },
                          {
                            field: "start_time",
                            headerName: "Start Date",
                            width: 240,
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                              return (
                                <>
                                  <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DateTimePicker
                                        name={`start_datetime`}
                                        className="text-filed-wrap date_field"
                                        renderInput={(props) => <TextField {...props} />}
                                        format="MM-DD-YYYY hh:mm a" // Format including time
                                        // value={dayjs(params?.row?.start_date)}
                                        value={dayjs(formik.values.schedules?.[params.id]?.start_datetime)}
                                        maxDateTime={dayjs(formik?.values?.schedules?.[params?.id]?.end_datetime)}
                                        minDateTime={dayjs(formik?.values?.schedules?.[params?.id - 1]?.end_datetime)}
                                        // value={rows?.scheduleData?.start_time}
                                        // disablePast
                                        onChange={(date) => {
                                          handleChange(
                                            params.id,
                                            `${"start_time"}`,
                                            dayjs(date.$d).format("YYYY-MM-DDTHH:mm:ss")
                                          );
                                          formik.setFieldValue(
                                            `schedules.${params.id}.start_datetime`,
                                            dayjs(date.$d).format("MM-DD-YYYY hh:mm a")
                                          );
                                          updateDates(
                                            params.id,
                                            dayjs(date.$d).format("MM-DD-YYYY hh:mm a"),
                                            formik.values.schedules?.[params.id]?.end_datetime,
                                            true
                                          );
                                        }}
                                      />
                                    </LocalizationProvider>
                                    {formik.touched.schedules?.[params.id]?.["start_datetime"] &&
                                      formik.errors.schedules?.[params.id]?.["start_datetime"] && (
                                        <FormHelperText style={{ color: "#d32f2f" }}>
                                          {formik.errors.schedules?.[params.id]?.["start_datetime"]}
                                        </FormHelperText>
                                      )}
                                  </div>
                                </>
                              );
                            },
                          },
                          {
                            field: "end_time",
                            headerName: "End Date",
                            width: 240,
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) => {
                              return (
                                <>
                                  <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DateTimePicker
                                        className="text-filed-wrap date_field"
                                        name={`end_datetime`}
                                        renderInput={(props) => <TextField {...props} />}
                                        format="MM-DD-YYYY hh:mm a" // Format including time
                                        value={dayjs(formik.values.schedules?.[params.id]?.end_datetime)}
                                        // value={rows?.scheduleData?.end_date}
                                        // disablePast
                                        minDateTime={dayjs(formik?.values?.schedules?.[params?.id]?.start_datetime)}
                                        onChange={(date) => {
                                          handleChange(
                                            params.id,
                                            `${"end_time"}`,
                                            dayjs(date.$d).format("YYYY-MM-DDTHH:mm:ss")
                                          );
                                          formik.setFieldValue(
                                            `schedules.${params.id}.end_datetime`,
                                            dayjs(date.$d).format("MM-DD-YYYY hh:mm a")
                                          );
                                          updateDates(
                                            params.id,
                                            formik.values.schedules?.[params.id]?.start_datetime,
                                            dayjs(date.$d).format("MM-DD-YYYY hh:mm a"),
                                            false
                                          );
                                        }}
                                      />
                                    </LocalizationProvider>
                                    {formik.touched.schedules?.[params.id]?.["end_datetime"] &&
                                      formik.errors.schedules?.[params.id]?.["end_datetime"] && (
                                        <FormHelperText style={{ color: "#d32f2f" }}>
                                          {formik.errors.schedules?.[params.id]?.["end_datetime"]}
                                        </FormHelperText>
                                      )}
                                  </div>
                                </>
                              );
                            },
                          },
                          {
                            field: "action",
                            headerName: "Action",
                            width: 70,
                            headerAlign: "center",
                            align: "center",
                            sortable: false,
                            disableColumnMenu: true,
                            renderCell: (params) => (
                              <>
                                <div
                                  className="action_icon-weap action_icon-weap-secule"
                                  onClick={() => handleDeleteRow(params.id)}>
                                  <GoTrash style={{ cursor: "pointer" }} />
                                </div>
                              </>
                            ),
                          },
                        ]}
                        initialState={{
                          pagination: false,
                        }}
                        rowSelection={false}
                      />
                    </div>
                  </div>
                  <div>
                    <button className="schedule-add-data-btn" type="button" onClick={() => handleAddRow()}>
                      <RxPlus />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal_footer">
                <button onClick={() => handleClose()} className="btn btn-border">
                  Cancel
                </button>
                <button type="submit" disabled={loading} className="btn btn-primary">
                  {schedule_id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </>
      </Modal>
      <DeleteModal
        open={openDeleteModal}
        HandleClose={() => setOpenDeleteModal(false)}
        HandleDelete={() => handleDeleteConfirm()}
        id={selectedRowId}
        message="Are you sure you want to delete?"
      />
    </>
  );
}

export default AddEditSchedule;
