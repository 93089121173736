import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { BsArrowLeft } from "react-icons/bs";
import "./View.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddEditTask from "../AddEditForm/AddEditTask";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { displayDuration } from "../../../Mixin/DisplayDuration";
import { Decryption, Encryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../FileInput/FileDisplay";

const TaskView = () => {
  const params = useParams();
  const { id } = params || {};
  const [task, setTask] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [DeleteTask, SetDeleteTask] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const decryptedId = Decryption(id);
  const { company_id, location_id } = useFilter();
  const state = location?.state?.task_id;
  const parsmstaskId = Decryption(state);

  console.log("location : ", location);

  const fetchTask = () => {
    setLoading(true);
    const ids = state ? parsmstaskId : decryptedId;
    if (ids) {
      try {
        httpService.get(`task/get_task/${ids}`).then((response) => {
          if (response?.data?.length > 0) {
            setTask(response?.data[0]);
          }
          setDeleteImg("");
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
      } catch (error) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchTask();
  }, [params?.id, showLoginPopup, parsmstaskId]);

  const handleOpen = () => {
    setOpen(true);
    fetchTask();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteImg("");
    SetDeleteTask(false);
    fetchTask();
  };

  const HandleDeleteTask = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(`${"task/delete_task"}/${decryptedId}`);
        navigate("/task");
        if (response) {
          toast.success("Task Deleted successfully", {
            position: "top-right",
          });
          SetDeleteTask(false);
          fetchTask();
        }
      } catch (error) {
        SetDeleteTask(false);
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...task.attachment]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in task) {
      if (key === "attachment") {
        for (let i = 0; i < newArray.length; i++) {
          formData.append(`existFile[${i}]`, newArray[i]);
        }
      } else {
        if (task[key] !== null) {
          const value = key === "sort_order" ? task[key] || "" : task[key];
          formData.append(key, value);
        }
      }
    }
    try {
      let response = await httpService.put(`${"task/edit_task"}/${decryptedId}`, formData);
      if (response.message) {
        SetDeleteTask(false);
        fetchTask();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding maintenance:", error);
      SetDeleteTask(false);
      // toast.error(error, {
      //   position: "top-right",
      // });
    }
  };

  const duration = displayDuration(task?.days, task?.hours, task?.minutes);

  const handleBackClick = () => {
    if (location.state?.from === "dashboard") {
      navigate("/dashboard");
    } else if (location.state?.from === "taskModule") {
      navigate("/task");
    } else {
      const urlEncodedId = Encryption(location.state.prev_id);
      navigate(`/schedule/view/${urlEncodedId}`); // Default to task module if origin is unknown
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="maintenance-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => handleBackClick()} />
              </span>
              Task Details - [{task?.name ? task?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button type="submit" className="btn btn-edit" onClick={() => handleOpen()}>
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                SetDeleteTask(true);
              }}>
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            {/* <div className="col-md-2">
              <div>
                <img src={Milestone} alt="Maintenance img" />
              </div>
            </div> */}
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Task Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{task.name ? task.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Duration</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{duration ? duration : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">{task.description ? task.description : "--"}</p>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{task?.version ? task?.version : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Company</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{task?.company_name ? task?.company_name : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Location</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{task?.location_name ? task?.location_name : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span>{task?.milestone_name ? task?.milestone_name : "--"}</span>
                  </div>
                </div>
                <div className="col-md-2 mb-4">
                  <div className="d-flex justify-content-between">
                    <label>Minimum Emp</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <div>{task?.min_employee ? task?.min_employee : "--"}</div>
                  </div>
                </div>
                <div className="col-md-2 mb-4">
                  <div className="d-flex justify-content-between">
                    <label>Maximum Emp</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <div>{task?.max_employee ? task?.max_employee : "--"}</div>
                  </div>
                </div>
                <div className="col-md-2 mb-4">
                  <div className="d-flex justify-content-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <div>{task?.version ? task?.version : "--"}</div>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Activity</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span>{task?.activity_name ? task?.activity_name : "--"}</span>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={task?.attachment}
                  handleDelete={() => SetDeleteTask(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <AddEditTask
          open={open}
          handleClose={() => handleClose()}
          task_id={decryptedId}
          isAdd={false}
          fetchTask={() => fetchTask()}
          companyId={company_id}
          locationId={location_id}
        />
      )}

      <DeleteModal
        open={DeleteTask}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeleteTask()}
      />
    </>
  );
};

export default TaskView;
